import React from 'react';
import WorkingPage from "../WorkingPage/WorkinpPage";

const Garage=() => {

    return (
       <>
           <WorkingPage></WorkingPage>
       </>
    )
}

export default Garage;