import { SVGProps } from "react";
import { JSX } from "react/jsx-runtime";
import {NavLink} from "react-router-dom";

const navigation = [

    {
        name: 'GitHub',
        href: 'https://simim53.github.io/HOME/starter-topic.html',
        icon: (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
            <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                <path
                    fillRule="evenodd"
                    d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                    clipRule="evenodd"
                />
            </svg>
        ),
    },
    {
        name: 'Support',
        href: '/help',
        icon: (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
            <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" {...props}>
                <path
                    d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z"
                />
            </svg>

        ),
    },

]

const Footer = () => {
    return (
        <div className="bg-gray-200 dark:bg-gray-500">
            <div className="mx-auto max-w-8xl py-2 px-12 flex items-center justify-between">
                <div className="flex space-x-3 order-2">
                    {navigation.map((item) => (
                        <NavLink to={item.href} key={item.name}>
                                <item.icon className="h-6 w-6 text-gray-400 dark:text-gray-300 hover:text-white" aria-hidden="true"/>
                        </NavLink>
                    ))}
                </div>
                <div className="order-1 mt-0">
                    <p className="text-center text-xs leading-5 text-gray-500 dark:text-gray-300">
                        MyHome V.{process.env.REACT_APP_VERSION}
                    </p>
                </div>
            </div>
        </div>
    )
}
export default Footer