import React, {Fragment, useContext, useEffect, useState} from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon } from '@heroicons/react/24/outline'
import logo from  '../../logo.jpg'
import ThemeSwitcher from "../ThemeSwitcher/ThemeSwitcher";
import Hamburger from "hamburger-react";
import {NavLink, useLocation} from "react-router-dom";
import {AuthContext} from "../../authentification/authentification";


const navigation = [
    { name: 'Volets', href: '/shutters'},
    { name: 'Garage', href: '/garage'},
    { name: 'Portail', href: '/gate'},
]

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

const Navbar = () => {
    const [currentNavItem, setCurrentNavItem] = useState(window.location.pathname);
    const location = useLocation();
    const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);


    useEffect(() => {
        setCurrentNavItem(location.pathname);
    }, [location]);

    function handleLogout() {
        setIsAuthenticated(false);
    }

    return (
        <Disclosure as="nav" className="bg-gray-800 shadow-[0px_4px_4px_rgba(0,0,0,0.5)]">
            {({ open }) => (
                <>
                    <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                        <div className="relative flex h-16 items-center justify-between">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                {/* Mobile menu button*/}
                                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-1 text-gray-400 hover:bg-gray-700 hover:text-white">
                                    <span className="absolute -inset-0.5" />
                                    <span className="sr-only">Open main menu</span>
                                    <Hamburger size={24} toggled={open} color="#9ca3af" />

                                </Disclosure.Button>
                            </div>
                            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                                <div className="flex flex-shrink-0 items-center">
                                    <NavLink to={"/"} onClick={() => setCurrentNavItem("/")}>
                                        <img
                                            className="h-10 w-auto"
                                            src={logo}
                                            alt="Your Company"
                                        />
                                    </NavLink>
                                </div>
                                <div className="hidden sm:ml-6 sm:block">
                                    <div className="flex space-x-4">
                                        {navigation.map((item) => (
                                            <NavLink
                                                key={item.name}
                                                to={item.href}
                                                onClick={() => setCurrentNavItem(item.href)} // Mettre à jour l'état local lorsque l'utilisateur clique sur un élément de navigation
                                                className={classNames(
                                                    currentNavItem.includes(item.href) ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                    'rounded-md px-3 py-2 text-sm font-medium'
                                                )}
                                                aria-current={currentNavItem.includes(item.href) ? 'page' : undefined}
                                            >

                                                {item.name}
                                            </NavLink>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div
                                className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">

                                {isAuthenticated && (
                                    <button
                                        type="button"
                                        className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white"
                                    >
                                        <span className="absolute -inset-1.5"/>
                                        <span className="sr-only">View notifications</span>
                                        <BellIcon className="h-6 w-6" aria-hidden="true"/>
                                    </button>
                                )}

                                <ThemeSwitcher buttonClassName="relative ml-3 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white" ></ThemeSwitcher>


                                {/* Profile dropdown */}
                                <Menu as="div" className="relative ml-3">
                                    <div>
                                        <Menu.Button
                                            className="relative flex rounded-full bg-gray-800 text-sm text-gray-400 hover:text-white">
                                            <span className="absolute -inset-1.5 "/>
                                            <span className="sr-only">Open user menu</span>
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 viewBox="0 0 24 24"
                                                 fill="none"
                                                 strokeWidth="1.5" stroke="currentColor" className="h-8 w-8 rounded-full">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                                            </svg>


                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items
                                            className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">

                                            {isAuthenticated && (
                                                <>
                                                    <Menu.Item>
                                                        {({active}) => (
                                                            <NavLink to={"/login"}>
                                                                <a
                                                                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                                >
                                                                    Mon compte
                                                                </a>
                                                            </NavLink>
                                                        )}
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        {({active}) => (
                                                            <NavLink to={"/login"}>
                                                                <a
                                                                    onClick={handleLogout}
                                                                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                                >
                                                                    Se déconnecter
                                                                </a>
                                                            </NavLink>
                                                        )}
                                                    </Menu.Item>
                                                </>
                                            )}

                                            {!isAuthenticated && (
                                                <>
                                                    <Menu.Item>
                                                        {({active}) => (
                                                            <NavLink to={"/login"}>
                                                                <a
                                                                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                                >
                                                                    Se connecter
                                                                </a>
                                                            </NavLink>
                                                        )}
                                                    </Menu.Item>
                                                </>
                                            )}
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="sm:hidden">
                        <div className="space-y-1 px-2 pb-3 pt-2">
                            {navigation.map((item) => (
                                <NavLink to={item.href} key={item.name}>
                                    <Disclosure.Button
                                        as="a"
                                        onClick={() => setCurrentNavItem(item.href)}
                                        className={classNames(
                                            currentNavItem.includes(item.href) ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                            'block rounded-md px-3 py-2 text-base font-medium'
                                        )}
                                        aria-current={currentNavItem.includes(item.href) ? 'page' : undefined}
                                        key={item.name}
                                    >
                                        {item.name}
                                    </Disclosure.Button>
                                </NavLink>
                            ))}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}
export default Navbar