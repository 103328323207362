import React from 'react';
import {NavLink} from "react-router-dom";
import volet from "../../jpg/Volet-roulant-renovation-manuel.jpg"
import garage from "../../jpg/garage-door-1_.jpg"
import portail from "../../jpg/portail-aluminium-CAMP-gris-anthracite.jpg"

const posts = [
    {
        title: 'Contrôler vos volets',
        href: '/shutters',
        category: { name: 'Volets', href: '#' },
        description: '',
        imageUrl: volet,

    },
    {
        title: 'Contrôler votre porte de garage',
        href: '/garage',
        category: { name: 'Garage', href: '#' },
        description: '',
        imageUrl: garage,
    },
    {
        title: 'Contrôler votre portail',
        href: '/gate',
        category: { name: 'Portail', href: '#' },
        description: '',
        imageUrl: portail,

    },

]

const Home=() => {

    return (
        <div className="relative bg-gray-50 dark:bg-gray-600 px-6 pt-16 pb-20 lg:px-8 lg:pt-24 lg:pb-28">
            <div className="absolute inset-0">
                <div className="h-1/3 bg-white dark:bg-gray-600 sm:h-2/3" />
            </div>
            <div className="relative mx-auto max-w-7xl">
                <div className="text-center">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl">Bienvenue</h2>
                    <p className="mx-auto mt-3 max-w-2xl text-xl text-gray-500 dark:text-gray-200 sm:mt-4">
                        MyHome est l'application domotique de référence
                    </p>
                </div>
        <div className="relative mx-auto max-w-7xl">
            <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
                {posts.map((post) => (
                    <NavLink to={post.href} key={post.title} className="flex flex-col overflow-hidden rounded-lg bg-white shadow-lg hover:shadow-2xl hover:bg-gray-100">
                        <div className="flex-shrink-0">
                            <img className="h-48 w-full object-cover" src={String(post.imageUrl)} alt=""/>
                        </div>
                        <div className="flex flex-1 flex-col justify-between p-6">
                            <div className="flex-1">
                                <p className= "font-medium text-indigo-600">
                                    <h1>
                                        {post.category.name}
                                    </h1>
                                </p>
                                <div className="mt-2 block">
                                    <h2 className="font-semibold text-gray-900">{post.title}</h2>
                                    <h2 className="mt-3 text-base text-gray-500">{post.description}</h2>
                                </div>
                            </div>
                        </div>
                    </NavLink>
                ))}
            </div>
        </div>
            </div>
        </div>
    );
}

export default Home;