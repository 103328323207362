import React, {useEffect} from 'react';

// Define the global YTFeedbackForm variable
declare global {
    interface Window {
        YTFeedbackForm: {
            renderFeedbackButton: (target: HTMLElement, options: any) => void;
        };
    }
}
const HelpDesk = () => {
    useEffect(() => {
            // Créez un élément de script
            const script = document.createElement('script');

            // Définissez les attributs du script
            script.type = 'text/javascript';
            script.id = '70863abe-0e47-4d0f-9423-c7c6fcb10458';
            script.setAttribute('data-yt-url', 'https://production-line.dismee.com/youtrack');
            script.src = 'https://production-line.dismee.com/youtrack/static/simplified/form/form-entry.js';
            script.setAttribute('data-theme', 'light');
            script.setAttribute('data-lang', 'fr');

            const formContainer = document.getElementById("help_desk_form");
            if (formContainer) {
                formContainer.appendChild(script);
            }

            // Nettoyez le script lorsque le composant est démonté
            return () => {
                const formContainer = document.getElementById("help_desk_form");
                const scriptElement = document.getElementById("70863abe-0e47-4d0f-9423-c7c6fcb10458");

                if (formContainer && scriptElement) {
                    formContainer.removeChild(scriptElement);
                }
            };
        },
        []);
    return (
        <div className="mx-auto max-w-fit">
            <div className="content-center" id="help_desk_form"></div>
        </div>
    );
};

export default HelpDesk;
