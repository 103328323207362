import React, { createContext, useState, useEffect, ReactNode, Dispatch, SetStateAction } from 'react';

interface AuthContextType {
    isAuthenticated: boolean;
    setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
}

export const AuthContext = createContext<AuthContextType>({
    isAuthenticated: false,
    setIsAuthenticated: () => {},
});

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

    const [isAuthenticated, setIsAuthenticatedState] = useState(
        localStorage.getItem('token') != null && localStorage.getItem('token') !== ''
    );

    const setIsAuthenticated: Dispatch<SetStateAction<boolean>> = (value) => {
        if (!value) {
            localStorage.removeItem('token'); // Supprimer le token lorsque l'utilisateur se déconnecte
        }
        setIsAuthenticatedState(value);
    };

    // Mettre à jour l'état d'authentification lorsqu'un changement est détecté dans le stockage local
    useEffect(() => {
        const handleAuthChange = () => {
            setIsAuthenticated(localStorage.getItem('token') != null && localStorage.getItem('token') !== '');
        };

        window.addEventListener('storage', handleAuthChange);

        return () => {
            window.removeEventListener('storage', handleAuthChange);
        };
    }, []);

    return (
        <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
            {children}
        </AuthContext.Provider>
    );
};
