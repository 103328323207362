import React, {useEffect, useState} from "react";
import roomLogo from "../../../svg/Room.svg";
import Spinner from "../../Spinner/spinner";
import Apis from "../../../api/Apis";
import BottomNavbar from '../BottomTabNavBarShutter/BottomNavBarShutter';

// Définition du type pour la carte avec deux clés et une valeur
type RoomMap = Map<string, boolean>;
const Rooms=() => {

    const [rooms, setRooms] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const { roomApi } = Apis();
    const [roomMap, setRoomMap] = useState<RoomMap>(new Map<string, boolean>());
    const [disableMap, setDisableMap] = useState<Map<string, boolean>>(new Map<string, boolean>());

    function handleTransmitter(roomId:any,channelId:any) {
        roomApi.postRoomChannel(roomId,channelId)
            .then(response => {
                if (response.status === 200) {

                    // récupérer l'état de l'état opposé
                    var chanOpId = channelId === '1' ? '2' :'1';
                    if(roomMap.get(`${roomId}-${chanOpId}`)){

                        setDisableMap(prevMap => {
                            const updatedMap = new Map(prevMap);
                            updatedMap.set(`${roomId}-${chanOpId}`, false);
                            return updatedMap;
                        });

                        // Création d'une nouvelle Map pour mettre à jour le state
                        const newShutterMap = new Map(roomMap);
                        newShutterMap.set(`${roomId}-${chanOpId}`, false);
                        setRoomMap(newShutterMap);


                    }
                    else {

                        // Désactiver le bouton associé à cette entrée spécifique dans la carte
                        setDisableMap(prevMap => {
                            const updatedMap = new Map(prevMap);
                            updatedMap.set(`${roomId}-${channelId}`, true);
                            return updatedMap;
                        });

                        // Création d'une nouvelle Map pour mettre à jour le state
                        const newShutterMap = new Map(roomMap);
                        newShutterMap.set(`${roomId}-${channelId}`, true);
                        setRoomMap(newShutterMap);

                        // Utilisation d'une fonction de rappel pour garantir l'utilisation de la dernière valeur de shutterMap
                        setTimeout(() => {
                            setRoomMap(prevMap => {
                                const updatedMap = new Map(prevMap);
                                updatedMap.set(`${roomId}-${channelId}`, false);
                                return updatedMap;
                            });

                            // Activer à nouveau le bouton après le timeout
                            setDisableMap(prevMap => {
                                const updatedMap = new Map(prevMap);
                                updatedMap.set(`${roomId}-${channelId}`, false);
                                return updatedMap;
                            });
                        }, 60000);
                    }
                }
            })
    }

    useEffect(() => {
        roomApi.getRooms( 0,  100)
            .then(response => {
                setRooms(response?.data?._embedded?.roomDTOList || [] );
            })
            .finally(() => setIsLoading(false));
    },[]);

    return (

        <div className="mb-20">
            <ul role="list"
                className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3">
                {isLoading ? (
                    <Spinner/>
                ) : (
                    rooms.map(room => {
                            if (room !== null && room !== undefined)
                                return (

                                    <li key={room.id}
                                        className="col-span-1 rounded-lg bg-white dark:bg-gray-200 shadow-[0px_4px_4px_rgba(0,0,0,0.5)]">
                                        <div className="flex w-full items-center justify-between space-x-6 p-2">
                                            <img src={roomLogo} alt={room.name}
                                                 className="h-20 w-20 flex-shrink-0"/>
                                            <div className="flex-1 truncate">
                                                <div className="flex items-center space-x-3">
                                                    <h3 className="truncate text-sm font-medium text-gray-900">{room.name}</h3>
                                                </div>
                                                <p className="mt-1 truncate text-sm text-gray-500">{room.description}</p>
                                            </div>
                                            <div className="flex-1 truncate divide-y">

                                                <div className="flex-1 truncate divide-y">
                                                    <button
                                                        type="button"
                                                        disabled={disableMap.get(`${room.id}-${1}`)}
                                                        className="inline-flex w-24 items-center rounded-t border border-transparent bg-green-500 hover:bg-green-400 px-3 py-2 text-sm font-medium text-white transform active:scale-95 transition-transform disabled:bg-green-300 disabled:active:scale-100"
                                                        onClick={() => handleTransmitter(room.id, '1')}
                                                    >
                                                        {roomMap.get(`${room.id}-1`) ? (
                                                            <svg aria-hidden="true" role="status"
                                                                 className="-ml-0.5 mr-2 h-4 w-4 animate-spin"
                                                                 viewBox="0 0 100 101" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                    fill="#E5E7EB"/>
                                                                <path
                                                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                    fill="currentColor"/>
                                                            </svg>
                                                        ) : (
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                 viewBox="0 0 24 24" strokeWidth="1.5"
                                                                 stroke="currentColor"
                                                                 className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true">
                                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                                      d="m4.5 18.75 7.5-7.5 7.5 7.5"/>
                                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                                      d="m4.5 12.75 7.5-7.5 7.5 7.5"/>
                                                            </svg>
                                                        )}
                                                        Ouvrir
                                                    </button>

                                                    <button
                                                        type="button"
                                                        disabled={disableMap.get(`${room.id}-${2}`)}
                                                        className="relative flex flex-1 w-24 items-center rounded-b bg-red-500 hover:bg-red-400 px-3 py-2 text-sm font-medium text-white transform active:scale-95 transition-transform disabled:bg-red-300 disabled:active:scale-100"
                                                        onClick={() => handleTransmitter(room.id, '2')}
                                                    >
                                                        {roomMap.get(`${room.id}-2`) ? (
                                                            <svg aria-hidden="true" role="status"
                                                                 className="-ml-0.5 mr-2 h-4 w-4 animate-spin"
                                                                 viewBox="0 0 100 101" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                    fill="#E5E7EB"/>
                                                                <path
                                                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                    fill="currentColor"/>
                                                            </svg>
                                                        ) : (
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                 viewBox="0 0 24 24" strokeWidth="1.5"
                                                                 stroke="currentColor"
                                                                 className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true">
                                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                                      d="m4.5 5.25 7.5 7.5 7.5-7.5m-15 6 7.5 7.5 7.5-7.5"/>
                                                            </svg>
                                                        )}
                                                        Fermer
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                )
                                    ;
                            else
                                return null;

                        }
                    ))
                }
            </ul>
            <BottomNavbar></BottomNavbar>
        </div>
    );

}

export default Rooms;