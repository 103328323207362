import React from 'react';
import Home from "./components/Home/home";
import Gate from "./components/Gate/Gate";
import Garage from "./components/Garage/Garage";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import HelpDesk from "./components/HelpDesk/HelpDesk";
import Navbar from "./components/NavBar/navbar";
import Footer from "./components/Footer/footer";
import Shutters from "./components/Shutters/shutter";
import Login from "./components/Login/Login";
import Rooms from "./components/Shutters/Rooms/room";
import Tasks from "./components/Shutters/Tasks/tasks";
import {AuthProvider} from "./authentification/authentification";

function App() {
    return (
        <div className="flex flex-col h-screen">
            <AuthProvider>
                <BrowserRouter>
                    <header>
                        <Navbar></Navbar>
                    </header>

                    <main
                        className="px-2 py-5 flex-grow bg-transparent dark:bg-gray-600 overflow-x-hidden overscroll-y-auto">

                            <div className="mx-auto max-w-6xl">

                            <Routes>
                                <Route path="/" element={<Home/>}/>

                                <Route path="/shutters" element={<Shutters/>}/>
                                <Route path="/shutters/rooms" element={<Rooms/>}/>
                                <Route path="/shutters/tasks" element={<Tasks/>}/>

                                <Route path="/garage" element={<Garage/>}/>
                                <Route path="/gate" element={<Gate/>}/>
                                <Route path="/login" element={<Login/>}/>

                                <Route path="/help" element={<HelpDesk/>}/>

                            </Routes>

                        </div>

                    </main>

                    <footer>
                        <Footer></Footer>
                    </footer>
                </BrowserRouter>
            </AuthProvider>
        </div>
    );
}

export default App
