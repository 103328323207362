/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface ActionDTO
 */
export interface ActionDTO {
    /**
     * 
     * @type {{ [key: string]: Link; }}
     * @memberof ActionDTO
     */
    '_links'?: { [key: string]: Link; };
    /**
     * 
     * @type {number}
     * @memberof ActionDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActionDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ActionDTO
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActionDTO
     */
    'channel_id'?: number;
    /**
     * 
     * @type {Array<ShutterDTO>}
     * @memberof ActionDTO
     */
    'shutters'?: Array<ShutterDTO>;
}
/**
 * 
 * @export
 * @interface AuthRequest
 */
export interface AuthRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthRequest
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof AuthRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface EntityModelActionDTO
 */
export interface EntityModelActionDTO {
    /**
     * 
     * @type {{ [key: string]: Link; }}
     * @memberof EntityModelActionDTO
     */
    '_links'?: { [key: string]: Link; };
    /**
     * 
     * @type {number}
     * @memberof EntityModelActionDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EntityModelActionDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelActionDTO
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof EntityModelActionDTO
     */
    'channel_id'?: number;
    /**
     * 
     * @type {Array<ShutterDTO>}
     * @memberof EntityModelActionDTO
     */
    'shutters'?: Array<ShutterDTO>;
}
/**
 * 
 * @export
 * @interface EntityModelRoomDTO
 */
export interface EntityModelRoomDTO {
    /**
     * 
     * @type {{ [key: string]: Link; }}
     * @memberof EntityModelRoomDTO
     */
    '_links'?: { [key: string]: Link; };
    /**
     * 
     * @type {number}
     * @memberof EntityModelRoomDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EntityModelRoomDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelRoomDTO
     */
    'description'?: string;
    /**
     * 
     * @type {Array<ShutterDTO>}
     * @memberof EntityModelRoomDTO
     */
    'shutters'?: Array<ShutterDTO>;
}
/**
 * 
 * @export
 * @interface EntityModelShutterDTO
 */
export interface EntityModelShutterDTO {
    /**
     * 
     * @type {{ [key: string]: Link; }}
     * @memberof EntityModelShutterDTO
     */
    '_links'?: { [key: string]: Link; };
    /**
     * 
     * @type {number}
     * @memberof EntityModelShutterDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EntityModelShutterDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelShutterDTO
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof EntityModelShutterDTO
     */
    'code_id'?: number;
    /**
     * 
     * @type {RoomDTO}
     * @memberof EntityModelShutterDTO
     */
    'room'?: RoomDTO;
    /**
     * 
     * @type {Array<ActionDTO>}
     * @memberof EntityModelShutterDTO
     */
    'actions'?: Array<ActionDTO>;
}
/**
 * 
 * @export
 * @interface EntityModelTaskDTO
 */
export interface EntityModelTaskDTO {
    /**
     * 
     * @type {{ [key: string]: Link; }}
     * @memberof EntityModelTaskDTO
     */
    '_links'?: { [key: string]: Link; };
    /**
     * 
     * @type {number}
     * @memberof EntityModelTaskDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EntityModelTaskDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelTaskDTO
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface EntityModelUserDTO
 */
export interface EntityModelUserDTO {
    /**
     * 
     * @type {{ [key: string]: Link; }}
     * @memberof EntityModelUserDTO
     */
    '_links'?: { [key: string]: Link; };
    /**
     * 
     * @type {number}
     * @memberof EntityModelUserDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EntityModelUserDTO
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelUserDTO
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface Link
 */
export interface Link {
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'href'?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'hreflang'?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'deprecation'?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'profile'?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Link
     */
    'templated'?: boolean;
}
/**
 * 
 * @export
 * @interface PageMetadata
 */
export interface PageMetadata {
    /**
     * 
     * @type {number}
     * @memberof PageMetadata
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMetadata
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMetadata
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMetadata
     */
    'number'?: number;
}
/**
 * 
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Pageable
     */
    'sort'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PagedModelEntityModelActionDTO
 */
export interface PagedModelEntityModelActionDTO {
    /**
     * 
     * @type {PagedModelEntityModelActionDTOEmbedded}
     * @memberof PagedModelEntityModelActionDTO
     */
    '_embedded'?: PagedModelEntityModelActionDTOEmbedded;
    /**
     * 
     * @type {{ [key: string]: Link; }}
     * @memberof PagedModelEntityModelActionDTO
     */
    '_links'?: { [key: string]: Link; };
    /**
     * 
     * @type {PageMetadata}
     * @memberof PagedModelEntityModelActionDTO
     */
    'page'?: PageMetadata;
}
/**
 * 
 * @export
 * @interface PagedModelEntityModelActionDTOEmbedded
 */
export interface PagedModelEntityModelActionDTOEmbedded {
    /**
     * 
     * @type {Array<EntityModelActionDTO>}
     * @memberof PagedModelEntityModelActionDTOEmbedded
     */
    'actionDTOList'?: Array<EntityModelActionDTO>;
}
/**
 * 
 * @export
 * @interface PagedModelEntityModelRoomDTO
 */
export interface PagedModelEntityModelRoomDTO {
    /**
     * 
     * @type {PagedModelEntityModelRoomDTOEmbedded}
     * @memberof PagedModelEntityModelRoomDTO
     */
    '_embedded'?: PagedModelEntityModelRoomDTOEmbedded;
    /**
     * 
     * @type {{ [key: string]: Link; }}
     * @memberof PagedModelEntityModelRoomDTO
     */
    '_links'?: { [key: string]: Link; };
    /**
     * 
     * @type {PageMetadata}
     * @memberof PagedModelEntityModelRoomDTO
     */
    'page'?: PageMetadata;
}
/**
 * 
 * @export
 * @interface PagedModelEntityModelRoomDTOEmbedded
 */
export interface PagedModelEntityModelRoomDTOEmbedded {
    /**
     * 
     * @type {Array<EntityModelRoomDTO>}
     * @memberof PagedModelEntityModelRoomDTOEmbedded
     */
    'roomDTOList'?: Array<EntityModelRoomDTO>;
}
/**
 * 
 * @export
 * @interface PagedModelEntityModelShutterDTO
 */
export interface PagedModelEntityModelShutterDTO {
    /**
     * 
     * @type {PagedModelEntityModelShutterDTOEmbedded}
     * @memberof PagedModelEntityModelShutterDTO
     */
    '_embedded'?: PagedModelEntityModelShutterDTOEmbedded;
    /**
     * 
     * @type {{ [key: string]: Link; }}
     * @memberof PagedModelEntityModelShutterDTO
     */
    '_links'?: { [key: string]: Link; };
    /**
     * 
     * @type {PageMetadata}
     * @memberof PagedModelEntityModelShutterDTO
     */
    'page'?: PageMetadata;
}
/**
 * 
 * @export
 * @interface PagedModelEntityModelShutterDTOEmbedded
 */
export interface PagedModelEntityModelShutterDTOEmbedded {
    /**
     * 
     * @type {Array<EntityModelShutterDTO>}
     * @memberof PagedModelEntityModelShutterDTOEmbedded
     */
    'shutterDTOList'?: Array<EntityModelShutterDTO>;
}
/**
 * 
 * @export
 * @interface PagedModelEntityModelTaskDTO
 */
export interface PagedModelEntityModelTaskDTO {
    /**
     * 
     * @type {PagedModelEntityModelTaskDTOEmbedded}
     * @memberof PagedModelEntityModelTaskDTO
     */
    '_embedded'?: PagedModelEntityModelTaskDTOEmbedded;
    /**
     * 
     * @type {{ [key: string]: Link; }}
     * @memberof PagedModelEntityModelTaskDTO
     */
    '_links'?: { [key: string]: Link; };
    /**
     * 
     * @type {PageMetadata}
     * @memberof PagedModelEntityModelTaskDTO
     */
    'page'?: PageMetadata;
}
/**
 * 
 * @export
 * @interface PagedModelEntityModelTaskDTOEmbedded
 */
export interface PagedModelEntityModelTaskDTOEmbedded {
    /**
     * 
     * @type {Array<EntityModelTaskDTO>}
     * @memberof PagedModelEntityModelTaskDTOEmbedded
     */
    'taskDTOList'?: Array<EntityModelTaskDTO>;
}
/**
 * 
 * @export
 * @interface PagedModelEntityModelUserDTO
 */
export interface PagedModelEntityModelUserDTO {
    /**
     * 
     * @type {PagedModelEntityModelUserDTOEmbedded}
     * @memberof PagedModelEntityModelUserDTO
     */
    '_embedded'?: PagedModelEntityModelUserDTOEmbedded;
    /**
     * 
     * @type {{ [key: string]: Link; }}
     * @memberof PagedModelEntityModelUserDTO
     */
    '_links'?: { [key: string]: Link; };
    /**
     * 
     * @type {PageMetadata}
     * @memberof PagedModelEntityModelUserDTO
     */
    'page'?: PageMetadata;
}
/**
 * 
 * @export
 * @interface PagedModelEntityModelUserDTOEmbedded
 */
export interface PagedModelEntityModelUserDTOEmbedded {
    /**
     * 
     * @type {Array<EntityModelUserDTO>}
     * @memberof PagedModelEntityModelUserDTOEmbedded
     */
    'userDTOList'?: Array<EntityModelUserDTO>;
}
/**
 * 
 * @export
 * @interface RoomDTO
 */
export interface RoomDTO {
    /**
     * 
     * @type {{ [key: string]: Link; }}
     * @memberof RoomDTO
     */
    '_links'?: { [key: string]: Link; };
    /**
     * 
     * @type {number}
     * @memberof RoomDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RoomDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RoomDTO
     */
    'description'?: string;
    /**
     * 
     * @type {Array<ShutterDTO>}
     * @memberof RoomDTO
     */
    'shutters'?: Array<ShutterDTO>;
}
/**
 * 
 * @export
 * @interface ShutterDTO
 */
export interface ShutterDTO {
    /**
     * 
     * @type {{ [key: string]: Link; }}
     * @memberof ShutterDTO
     */
    '_links'?: { [key: string]: Link; };
    /**
     * 
     * @type {number}
     * @memberof ShutterDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShutterDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ShutterDTO
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof ShutterDTO
     */
    'code_id'?: number;
    /**
     * 
     * @type {RoomDTO}
     * @memberof ShutterDTO
     */
    'room'?: RoomDTO;
    /**
     * 
     * @type {Array<ActionDTO>}
     * @memberof ShutterDTO
     */
    'actions'?: Array<ActionDTO>;
}
/**
 * 
 * @export
 * @interface UserDTO
 */
export interface UserDTO {
    /**
     * 
     * @type {{ [key: string]: Link; }}
     * @memberof UserDTO
     */
    '_links'?: { [key: string]: Link; };
    /**
     * 
     * @type {number}
     * @memberof UserDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'password': string;
}

/**
 * ActionsControllerApi - axios parameter creator
 * @export
 */
export const ActionsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} actionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAction: async (actionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actionId' is not null or undefined
            assertParamExists('getAction', 'actionId', actionId)
            const localVarPath = `/api/actions/{actionId}`
                .replace(`{${"actionId"}}`, encodeURIComponent(String(actionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActions: async (pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getActions', 'pageable', pageable)
            const localVarPath = `/api/actions/listActions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActionsControllerApi - functional programming interface
 * @export
 */
export const ActionsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActionsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} actionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAction(actionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelActionDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAction(actionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActionsControllerApi.getAction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActions(pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModelEntityModelActionDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActions(pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActionsControllerApi.getActions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ActionsControllerApi - factory interface
 * @export
 */
export const ActionsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActionsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} actionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAction(actionId: string, options?: any): AxiosPromise<EntityModelActionDTO> {
            return localVarFp.getAction(actionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActions(pageable: Pageable, options?: any): AxiosPromise<PagedModelEntityModelActionDTO> {
            return localVarFp.getActions(pageable, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActionsControllerApi - object-oriented interface
 * @export
 * @class ActionsControllerApi
 * @extends {BaseAPI}
 */
export class ActionsControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} actionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionsControllerApi
     */
    public getAction(actionId: string, options?: RawAxiosRequestConfig) {
        return ActionsControllerApiFp(this.configuration).getAction(actionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionsControllerApi
     */
    public getActions(pageable: Pageable, options?: RawAxiosRequestConfig) {
        return ActionsControllerApiFp(this.configuration).getActions(pageable, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RoomsControllerApi - axios parameter creator
 * @export
 */
export const RoomsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} roomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoom: async (roomId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roomId' is not null or undefined
            assertParamExists('getRoom', 'roomId', roomId)
            const localVarPath = `/api/rooms/{roomId}`
                .replace(`{${"roomId"}}`, encodeURIComponent(String(roomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRooms: async (pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getRooms', 'pageable', pageable)
            const localVarPath = `/api/rooms/listRooms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} roomId 
         * @param {string} channelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRoomChannel: async (roomId: string, channelId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roomId' is not null or undefined
            assertParamExists('postRoomChannel', 'roomId', roomId)
            // verify required parameter 'channelId' is not null or undefined
            assertParamExists('postRoomChannel', 'channelId', channelId)
            const localVarPath = `/api/rooms/execute/{roomId}/{channelId}`
                .replace(`{${"roomId"}}`, encodeURIComponent(String(roomId)))
                .replace(`{${"channelId"}}`, encodeURIComponent(String(channelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoomsControllerApi - functional programming interface
 * @export
 */
export const RoomsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoomsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} roomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoom(roomId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelRoomDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoom(roomId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoomsControllerApi.getRoom']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRooms(pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModelEntityModelRoomDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRooms(pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoomsControllerApi.getRooms']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} roomId 
         * @param {string} channelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRoomChannel(roomId: string, channelId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRoomChannel(roomId, channelId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RoomsControllerApi.postRoomChannel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RoomsControllerApi - factory interface
 * @export
 */
export const RoomsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoomsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} roomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoom(roomId: string, options?: any): AxiosPromise<EntityModelRoomDTO> {
            return localVarFp.getRoom(roomId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRooms(pageable: Pageable, options?: any): AxiosPromise<PagedModelEntityModelRoomDTO> {
            return localVarFp.getRooms(pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} roomId 
         * @param {string} channelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRoomChannel(roomId: string, channelId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.postRoomChannel(roomId, channelId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoomsControllerApi - object-oriented interface
 * @export
 * @class RoomsControllerApi
 * @extends {BaseAPI}
 */
export class RoomsControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} roomId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomsControllerApi
     */
    public getRoom(roomId: string, options?: RawAxiosRequestConfig) {
        return RoomsControllerApiFp(this.configuration).getRoom(roomId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomsControllerApi
     */
    public getRooms(pageable: Pageable, options?: RawAxiosRequestConfig) {
        return RoomsControllerApiFp(this.configuration).getRooms(pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} roomId 
     * @param {string} channelId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomsControllerApi
     */
    public postRoomChannel(roomId: string, channelId: string, options?: RawAxiosRequestConfig) {
        return RoomsControllerApiFp(this.configuration).postRoomChannel(roomId, channelId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ShuttersControllerApi - axios parameter creator
 * @export
 */
export const ShuttersControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} shutterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShutter: async (shutterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shutterId' is not null or undefined
            assertParamExists('getShutter', 'shutterId', shutterId)
            const localVarPath = `/api/shutters/{shutterId}`
                .replace(`{${"shutterId"}}`, encodeURIComponent(String(shutterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShutters: async (pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getShutters', 'pageable', pageable)
            const localVarPath = `/api/shutters/listShutters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} shutterId 
         * @param {string} channelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postShutterChannel: async (shutterId: string, channelId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shutterId' is not null or undefined
            assertParamExists('postShutterChannel', 'shutterId', shutterId)
            // verify required parameter 'channelId' is not null or undefined
            assertParamExists('postShutterChannel', 'channelId', channelId)
            const localVarPath = `/api/shutters/{shutterId}/{channelId}`
                .replace(`{${"shutterId"}}`, encodeURIComponent(String(shutterId)))
                .replace(`{${"channelId"}}`, encodeURIComponent(String(channelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShuttersControllerApi - functional programming interface
 * @export
 */
export const ShuttersControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShuttersControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} shutterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShutter(shutterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelShutterDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShutter(shutterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShuttersControllerApi.getShutter']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShutters(pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModelEntityModelShutterDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShutters(pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShuttersControllerApi.getShutters']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} shutterId 
         * @param {string} channelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postShutterChannel(shutterId: string, channelId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postShutterChannel(shutterId, channelId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShuttersControllerApi.postShutterChannel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ShuttersControllerApi - factory interface
 * @export
 */
export const ShuttersControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShuttersControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} shutterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShutter(shutterId: string, options?: any): AxiosPromise<EntityModelShutterDTO> {
            return localVarFp.getShutter(shutterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShutters(pageable: Pageable, options?: any): AxiosPromise<PagedModelEntityModelShutterDTO> {
            return localVarFp.getShutters(pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} shutterId 
         * @param {string} channelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postShutterChannel(shutterId: string, channelId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.postShutterChannel(shutterId, channelId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShuttersControllerApi - object-oriented interface
 * @export
 * @class ShuttersControllerApi
 * @extends {BaseAPI}
 */
export class ShuttersControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} shutterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShuttersControllerApi
     */
    public getShutter(shutterId: string, options?: RawAxiosRequestConfig) {
        return ShuttersControllerApiFp(this.configuration).getShutter(shutterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShuttersControllerApi
     */
    public getShutters(pageable: Pageable, options?: RawAxiosRequestConfig) {
        return ShuttersControllerApiFp(this.configuration).getShutters(pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} shutterId 
     * @param {string} channelId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShuttersControllerApi
     */
    public postShutterChannel(shutterId: string, channelId: string, options?: RawAxiosRequestConfig) {
        return ShuttersControllerApiFp(this.configuration).postShutterChannel(shutterId, channelId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TasksControllerApi - axios parameter creator
 * @export
 */
export const TasksControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTask: async (taskId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('getTask', 'taskId', taskId)
            const localVarPath = `/api/tasks/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasks: async (pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getTasks', 'pageable', pageable)
            const localVarPath = `/api/tasks/listTasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExecuteTask: async (taskId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('postExecuteTask', 'taskId', taskId)
            const localVarPath = `/api/tasks/execute/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TasksControllerApi - functional programming interface
 * @export
 */
export const TasksControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TasksControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTask(taskId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelTaskDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTask(taskId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksControllerApi.getTask']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTasks(pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModelEntityModelTaskDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTasks(pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksControllerApi.getTasks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postExecuteTask(taskId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postExecuteTask(taskId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksControllerApi.postExecuteTask']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TasksControllerApi - factory interface
 * @export
 */
export const TasksControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TasksControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTask(taskId: string, options?: any): AxiosPromise<EntityModelTaskDTO> {
            return localVarFp.getTask(taskId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasks(pageable: Pageable, options?: any): AxiosPromise<PagedModelEntityModelTaskDTO> {
            return localVarFp.getTasks(pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExecuteTask(taskId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.postExecuteTask(taskId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TasksControllerApi - object-oriented interface
 * @export
 * @class TasksControllerApi
 * @extends {BaseAPI}
 */
export class TasksControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} taskId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksControllerApi
     */
    public getTask(taskId: string, options?: RawAxiosRequestConfig) {
        return TasksControllerApiFp(this.configuration).getTask(taskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksControllerApi
     */
    public getTasks(pageable: Pageable, options?: RawAxiosRequestConfig) {
        return TasksControllerApiFp(this.configuration).getTasks(pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} taskId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksControllerApi
     */
    public postExecuteTask(taskId: string, options?: RawAxiosRequestConfig) {
        return TasksControllerApiFp(this.configuration).postExecuteTask(taskId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersControllerApi - axios parameter creator
 * @export
 */
export const UsersControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {UserDTO} userDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (userDTO: UserDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userDTO' is not null or undefined
            assertParamExists('createUser', 'userDTO', userDTO)
            const localVarPath = `/api/users/createUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUser', 'userId', userId)
            const localVarPath = `/api/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getUsers', 'pageable', pageable)
            const localVarPath = `/api/users/listUsers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hello: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/hello`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthRequest} authRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (authRequest: AuthRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authRequest' is not null or undefined
            assertParamExists('login', 'authRequest', authRequest)
            const localVarPath = `/api/users/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersControllerApi - functional programming interface
 * @export
 */
export const UsersControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {UserDTO} userDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(userDTO: UserDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelUserDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(userDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersControllerApi.createUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelUserDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersControllerApi.getUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModelEntityModelUserDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersControllerApi.getUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hello(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hello(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersControllerApi.hello']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AuthRequest} authRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(authRequest: AuthRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(authRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersControllerApi.login']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersControllerApi - factory interface
 * @export
 */
export const UsersControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {UserDTO} userDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(userDTO: UserDTO, options?: any): AxiosPromise<EntityModelUserDTO> {
            return localVarFp.createUser(userDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userId: string, options?: any): AxiosPromise<EntityModelUserDTO> {
            return localVarFp.getUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(pageable: Pageable, options?: any): AxiosPromise<PagedModelEntityModelUserDTO> {
            return localVarFp.getUsers(pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hello(options?: any): AxiosPromise<string> {
            return localVarFp.hello(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthRequest} authRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(authRequest: AuthRequest, options?: any): AxiosPromise<string> {
            return localVarFp.login(authRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersControllerApi - object-oriented interface
 * @export
 * @class UsersControllerApi
 * @extends {BaseAPI}
 */
export class UsersControllerApi extends BaseAPI {
    /**
     * 
     * @param {UserDTO} userDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersControllerApi
     */
    public createUser(userDTO: UserDTO, options?: RawAxiosRequestConfig) {
        return UsersControllerApiFp(this.configuration).createUser(userDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersControllerApi
     */
    public getUser(userId: string, options?: RawAxiosRequestConfig) {
        return UsersControllerApiFp(this.configuration).getUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersControllerApi
     */
    public getUsers(pageable: Pageable, options?: RawAxiosRequestConfig) {
        return UsersControllerApiFp(this.configuration).getUsers(pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersControllerApi
     */
    public hello(options?: RawAxiosRequestConfig) {
        return UsersControllerApiFp(this.configuration).hello(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthRequest} authRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersControllerApi
     */
    public login(authRequest: AuthRequest, options?: RawAxiosRequestConfig) {
        return UsersControllerApiFp(this.configuration).login(authRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



