import React from 'react';
import {NavLink} from "react-router-dom";

const WorkingPage=() => {

    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="text-center">
                    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-20 0 190 190"
                             className="text-indigo-600  mx-auto h-40 w-auto dark:text-gray-300">
                            <path fill="currentColor"
                                  d="M77.87 147.48L84.68 135.19L91.28 136.52L94.73 130.4L80.12 123L83.83 119L97.22 126L104.33 113.37L125.33 147.18L77.87 147.48ZM67.8 65.94C69.34 54.73 87.49 57.28 84.22 69.46C80.92 81.79 66.31 76.72 67.8 65.94ZM78.66 113.78C79.04 118.58 75.81 119.47 73.66 119.55L72.56 146.8C72.56 146.8 65.25 150.8 64.88 143.62C64.48 135.75 63.64 121.1 63.64 121.1C63.64 121.1 47.7 114.1 42.64 110.94C38.89 108.6 37.71 105.74 38.64 101.94L30 97.55L30.06 97.37C27.8 96.37 25.39 92.7 26.97 89.57C26.49 90.45 35 73.22 35 73.22C35 73.22 46.59 73.66 55.07 73.9C68.61 74.3 76 84.59 76 84.59C76 84.59 78.34 109.73 78.66 113.78ZM40 83.07L34 92.9L41 96.57C43.71 91.71 46.06 87.35 48.43 83L40 83.07ZM66.3 89L57.55 105.19L70.55 111.97L66.3 89ZM48.08 119.44L29.67 147.77C29.67 147.77 22.37 146.16 25.45 139.65C29.35 131.43 37.29 113.79 37.29 113.79L48.08 119.44Z"
                            />
                        </svg>
                    </div>
                    <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-300 sm:text-5xl">Page
                        en
                        construction</h1>
                    <p className="mt-6 text-base leading-7 text-gray-600">Désolé, la page demandée n'existe pas
                        encore.</p>
                    <div className="mt-10 flex items-center justify-center gap-x-2 sm:gap-x-6">
                        <NavLink to={"/"}>
                            <a
                                href="#"
                                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Retour à l'accueil
                            </a>
                        </NavLink>

                        <NavLink to={"/help"}>
                            <a className="text-sm font-semibold text-gray-900 dark:text-gray-200">
                                Contacter le support <span aria-hidden="true">&rarr;</span>
                            </a>
                        </NavLink>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WorkingPage;