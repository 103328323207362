import React from 'react';
import WorkingPage from "../WorkingPage/WorkinpPage";

const Gate=() => {

    return (
        <>
            <WorkingPage></WorkingPage>
        </>
    )
}

export default Gate;