import React from 'react'
import {NavLink, useLocation} from "react-router-dom";

const BottomNavbar = () => {
    const location = useLocation();
    function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(' ')
    }

    return (

        <div
            className="fixed bottom-10 left-0 z-50 w-full h-16 bg-gray-100 border-t border-gray-200 dark:bg-gray-300 dark:border-gray-600">
            <div className="grid h-full max-w-lg grid-cols-3 mx-auto">
                <NavLink to="/shutters"
                         className={classNames(
                             location.pathname === '/shutters' ? 'shadow-inner bg-gray-50 text-blue-500 dark:bg-gray-800 dark:text-gray-300' : '',
                             'inline-flex flex-col items-center justify-center px-5 hover:text-blue-500 hover:bg-gray-50 dark:hover:bg-gray-800'
                         )}>
                    <button type="button"
                            className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group"

                    >
                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                             className="w-6 h-6 mb-1"
                             width="25.000000pt" height="25.000000pt" viewBox="0 0 512.000000 512.000000"
                             preserveAspectRatio="xMidYMid meet">

                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                               fill="currentColor" stroke="none">
                                <path d="M410 5109 c-32 -13 -78 -56 -96 -91 -21 -41 -21 -4875 0 -4916 19
                                -36 65 -78 99 -91 40 -15 4254 -15 4294 0 34 13 80 55 99 91 21 41 21 4875 0
                                4916 -19 36 -65 78 -99 91 -38 15 -4261 14 -4297 0z m520 -339 l0 -200 -240 0
                                -240 0 0 193 c0 107 3 197 7 200 3 4 111 7 240 7 l233 0 0 -200z m3110 0 l0
                                -200 -1480 0 -1480 0 0 200 0 200 1480 0 1480 0 0 -200z m628 -2 l2 -198 -240
                                0 -240 0 0 200 0 200 238 -2 237 -3 3 -197z m-3738 -508 l0 -160 -240 0 -240
                                0 0 160 0 160 240 0 240 0 0 -160z m3110 0 l0 -160 -1480 0 -1480 0 0 160 0
                                160 1480 0 1480 0 0 -160z m630 0 l0 -160 -240 0 -240 0 0 160 0 160 240 0
                                240 0 0 -160z m-3740 -470 l0 -160 -240 0 -240 0 0 160 0 160 240 0 240 0 0
                                -160z m3110 0 l0 -160 -1480 0 -1480 0 0 160 0 160 1480 0 1480 0 0 -160z
                                m630 0 l0 -160 -240 0 -240 0 0 160 0 160 240 0 240 0 0 -160z m-3740 -475 l0
                                -165 -240 0 -240 0 0 165 0 165 240 0 240 0 0 -165z m3110 0 l0 -165 -1003 0
                                c-993 0 -1004 0 -1022 -20 -25 -28 -23 -76 4 -103 l22 -22 999 -3 1000 -2 0
                                -150 0 -150 -1480 0 -1480 0 0 150 0 149 359 3 c337 3 360 4 380 22 27 25 28
                                79 1 106 -19 19 -33 20 -380 20 l-360 0 0 165 0 165 1480 0 1480 0 0 -165z
                                m630 0 l0 -165 -240 0 -240 0 0 165 0 165 240 0 240 0 0 -165z m-3740 -465 l0
                                -150 -240 0 -240 0 0 150 0 150 240 0 240 0 0 -150z m3740 0 l0 -150 -240 0
                                -240 0 0 150 0 150 240 0 240 0 0 -150z m-4010 -1210 c0 -994 -3 -946 57 -986
                                27 -18 71 -19 1534 -19 1468 0 1507 0 1528 19 27 25 28 79 1 106 -19 19 -33
                                20 -488 20 l-469 0 -6 167 c-4 91 -7 489 -7 885 l0 718 750 0 750 0 0 -885 0
                                -885 -154 0 c-135 0 -157 -2 -177 -18 -33 -27 -30 -78 6 -109 28 -24 30 -25
                                209 -21 158 3 185 5 209 22 60 40 57 -8 57 986 l0 910 105 0 105 0 -2 -1197
                                -3 -1198 -2105 0 -2105 0 -3 1198 -2 1197 105 0 105 0 0 -910z m1540 25 l0
                                -885 -695 0 -695 0 0 885 0 885 695 0 695 0 0 -885z m462 110 c2 -426 3 -823
                                3 -882 l0 -108 -155 0 -155 0 -3 883 -2 882 155 0 155 0 2 -775z"
                                />
                            </g>
                        </svg
                        >
                        <span
                            className="text-sm">Volets</span>
                    </button>
                </NavLink>
                <NavLink to="/shutters/rooms"
                         className={classNames(
                             location.pathname === '/shutters/rooms' ? 'shadow-inner bg-gray-50 text-blue-600 dark:bg-gray-800 dark:text-gray-300' : '',
                             'inline-flex flex-col items-center justify-center px-5 hover:text-blue-500 hover:bg-gray-50 dark:hover:bg-gray-800'
                         )}>
                    <button type="button"
                            className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">
                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                             className="w-6 h-6 mb-1"
                             viewBox="0 0 412.000000 412.000000"
                             preserveAspectRatio="xMidYMid meet">
                            <g transform="translate(0.000000,412.000000) scale(0.100000,-0.100000)"
                               fill="currentColor" stroke="none">
                                <path d="M16 4098 c-14 -20 -16 -228 -16 -2021 l0 -1999 29 -29 29 -29 2002 0
                                2002 0 29 29 29 29 0 1712 c0 1826 2 1756 -45 1774 -36 13 -82 6 -103 -17
                                l-22 -23 -2 -765 -3 -764 -191 -3 c-189 -2 -191 -3 -214 -27 -34 -37 -33 -90
                                4 -121 26 -23 32 -24 217 -24 l189 0 -2 -812 -3 -813 -1245 0 -1245 0 -5 233
                                c-5 231 -5 234 -29 253 -26 21 -78 25 -102 6 -35 -27 -39 -53 -39 -276 l0
                                -221 -552 2 -553 3 0 1885 0 1885 553 3 552 2 0 -1371 c0 -1023 3 -1376 12
                                -1392 28 -53 92 -64 133 -22 l25 24 0 306 0 305 794 0 793 0 27 26 c32 32 35
                                81 7 115 l-19 24 -799 5 -798 5 0 985 0 985 1245 0 1245 0 3 -105 c1 -58 7
                                -115 13 -127 26 -49 102 -52 139 -6 18 23 20 41 20 187 0 130 -3 166 -16 184
                                l-15 22 -2029 0 -2029 0 -15 -22z"/>
                            </g>
                        </svg>
                        <span
                            className="text-sm">Pièces</span>
                    </button>
                </NavLink>
                <NavLink to="/shutters/tasks"
                         className={classNames(
                             location.pathname === '/shutters/tasks' ? 'shadow-inner bg-gray-50 text-blue-600 dark:bg-gray-800 dark:text-gray-300' : '',
                             'inline-flex flex-col items-center justify-center px-5 hover:text-blue-500 hover:bg-gray-50 dark:hover:bg-gray-800'
                         )}>
                    <button type="button"
                            className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">
                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                             className="w-6 h-6 mb-1"
                             viewBox="0 0 512.000000 512.000000"
                             preserveAspectRatio="xMidYMid meet">

                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                               fill="currentColor" stroke="none">
                                <path d="M2480 5114 c-106 -19 -183 -51 -266 -110 -101 -71 -165 -160 -220
                                -304 -40 -104 -93 -157 -203 -199 -95 -37 -145 -66 -218 -127 l-51 -44 -299 0
                                c-312 0 -342 -4 -429 -48 -91 -47 -173 -163 -194 -271 -14 -73 -14 -3618 0
                                -3691 29 -153 157 -281 310 -310 72 -14 3228 -14 3300 0 153 29 281 157 310
                                310 14 73 14 3618 0 3691 -21 108 -103 224 -194 271 -87 44 -117 48 -430 48
                                l-299 0 -51 43 c-73 62 -122 91 -217 128 -110 42 -163 95 -203 199 -81 213
                                -203 332 -406 396 -47 15 -197 26 -240 18z m206 -214 c115 -40 202 -126 245
                                -245 70 -191 155 -275 344 -344 115 -42 206 -132 246 -245 15 -44 19 -82 19
                                -191 l0 -135 -980 0 -980 0 0 135 c0 109 4 147 19 191 40 113 130 203 245 244
                                188 68 275 154 345 345 76 207 293 314 497 245z m-1276 -763 c0 -1 -7 -34 -15
                                -72 -10 -50 -15 -135 -15 -297 l0 -228 1180 0 1180 0 0 228 c0 162 -5 247 -15
                                297 -8 38 -15 72 -15 73 0 2 107 2 238 0 l239 -3 48 -30 c32 -20 56 -45 72
                                -74 l23 -44 -2 -1832 -3 -1832 -27 -36 c-16 -19 -43 -46 -60 -59 l-33 -23
                                -1645 0 -1645 0 -33 23 c-17 13 -44 40 -60 59 l-27 36 -3 1830 -2 1830 21 44
                                c24 50 81 93 137 104 33 6 462 12 462 6z"
                                />
                                <path d="M2503 4661 c-127 -59 -103 -251 33 -277 47 -9 118 19 145 58 87 121
                                -43 281 -178 219z"/>
                                <path d="M1409 2921 c-21 -22 -29 -39 -29 -66 0 -27 8 -44 29 -66 39 -38 87
                                -39 130 -3 26 21 31 33 31 69 0 89 -97 129 -161 66z"/>
                                <path d="M1970 2855 l0 -95 885 0 885 0 0 95 0 95 -885 0 -885 0 0 -95z"/>
                                <path d="M1409 2331 c-21 -22 -29 -39 -29 -66 0 -83 105 -127 164 -69 39 40
                                 37 103 -5 138 -43 36 -91 35 -130 -3z"/>
                                <path d="M1970 2265 l0 -95 885 0 885 0 0 95 0 95 -885 0 -885 0 0 -95z"/>
                                <path d="M1409 1741 c-21 -22 -29 -39 -29 -66 0 -83 105 -127 164 -69 39 40
                                37 103 -5 138 -43 36 -91 35 -130 -3z"/>
                                <path d="M1970 1675 l0 -95 885 0 885 0 0 95 0 95 -885 0 -885 0 0 -95z"/>
                                <path d="M1411 1149 c-40 -39 -42 -85 -5 -128 21 -26 33 -31 69 -31 89 0 129
                                 97 66 161 -41 40 -89 40 -130 -2z"/>
                                <path d="M1970 1080 l0 -100 885 0 885 0 0 100 0 100 -885 0 -885 0 0 -100z"/>
                            </g>
                        </svg>
                        <span
                            className="text-sm">Tâches</span>
                    </button>
                </NavLink>
            </div>
        </div>
    )
}

export default BottomNavbar