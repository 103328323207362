import {
    Configuration,
    RoomsControllerApi,
    ShuttersControllerApi,
    TasksControllerApi
} from "../generated-sources/openapi";
import { UsersControllerApi } from "../generated-sources/openapi";
import axios, {AxiosError} from "axios";
import { useNavigate } from "react-router-dom";
import {AuthContext} from "../authentification/authentification";
import {useContext} from "react";


const Apis = () => {
    const navigate = useNavigate();
    const config = new Configuration();
    const axiosInstance = axios.create();
    const basepath = process.env.REACT_APP_SERVEUR_API_URL
    const usersControllerApi = new UsersControllerApi(config, basepath, axiosInstance);
    const shuttersControllerApi = new ShuttersControllerApi(config, basepath, axiosInstance);
    const tasksControllerApi = new TasksControllerApi(config, basepath, axiosInstance);
    const roomsControllerApi = new RoomsControllerApi(config, basepath, axiosInstance);
    const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);


    // Intercepteur pour mettre à jour les headers avec le token à chaque appel d'API
    axiosInstance.interceptors.request.use(
        config => {
            config.headers.Authorization = `Bearer ${localStorage.getItem('token') || ''}`;
            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );

    // Wrapper pour les appels API afin de gérer les erreurs localement
    const apiCallWrapper = async ({apiCall}: { apiCall: any }) => {
        try {
            const response = await apiCall();
            console.log("Réponse de l'appel à l'API " + apiCall + " : ", response)
            return response;
        } catch (error) {
            console.error("Erreur lors de l'appel API :", error);
            if ((error as AxiosError).response && (error as AxiosError).response?.status === 401) {
                setIsAuthenticated(false)
                navigate("/login");
            }
            else{
                // Gérer d'autres erreurs HTTP ici...
                //throw error; // Permet de propager l'erreur au composant appelant
            }

        }
    };

    const userApi = {
        login: (username:string,password:string) => apiCallWrapper({
            apiCall: () => usersControllerApi.login({
                username,
                password
            })
        }),
        // Ajoutez d'autres méthodes d'API de cette manière...


    };

    const shutterApi = {
        getShutters: (page:number, size:number) => apiCallWrapper({
            apiCall: () => shuttersControllerApi.getShutters({
                page: page,
                size: size
            })
        }),

        postShutterChannel: (shutterId:string, channelId:string) => apiCallWrapper({
            apiCall: () => shuttersControllerApi.postShutterChannel(shutterId, channelId)
        }),

        // Ajoutez d'autres méthodes d'API de cette manière...
    };

    const roomApi = {
        getRooms: (page:number, size:number) => apiCallWrapper({
            apiCall: () => roomsControllerApi.getRooms({
                page: page,
                size: size
            })
        }),

        postRoomChannel: (roomId:string, channelId:string) => apiCallWrapper({
            apiCall: () => roomsControllerApi.postRoomChannel(roomId, channelId)
        }),

        // Ajoutez d'autres méthodes d'API de cette manière...
    };

    const taskApi = {
        getTasks: (page:number, size:number) => apiCallWrapper({
            apiCall: () => tasksControllerApi.getTasks({
                page: page,
                size: size
            })
        }),

        postExecuteTask: (taskId:string) => apiCallWrapper({
            apiCall: () => tasksControllerApi.postExecuteTask(taskId)
        }),

        // Ajoutez d'autres méthodes d'API de cette manière...
    };


    return { userApi, shutterApi, roomApi, taskApi };
}

export default Apis;
