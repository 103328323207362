import React, {useContext, useState} from 'react';
import Spinner from "../Spinner/spinner"
import Apis from "../../api/Apis";
import {NavLink} from "react-router-dom";
import {XCircleIcon} from "@heroicons/react/16/solid";
import {AuthContext} from "../../authentification/authentification";

const Login =() => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const { userApi } = Apis();



    const handleSubmit  = async (event: { preventDefault: () => void; }) => {
        setIsLoading(true)

        //Prevent page reload
        event.preventDefault();

        userApi.login(username,password)
            .then(response =>{
                console.log(response.data)
                localStorage.setItem('token', response.data);
                setIsAuthenticated(true);
                setError('');
            })
            .catch(() => setError('Une erreur est survenue lors de l\'authentification'))
            .finally(() => setIsLoading(false))
    };

    function handleLogout() {
        setIsAuthenticated(false);
    }


    return (
        <>
            { isLoading &&
                <Spinner />
            }
            {error ?
                <div className="rounded-md bg-red-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true"/>
                        </div>
                        <div className="ml-3">
                            <h3 className="text-sm font-medium text-red-800">{error}</h3>
                        </div>
                    </div>
                </div>
                : null}
            {!isAuthenticated && (
                <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                             className="text-indigo-500  mx-auto h-10 w-auto dark:text-gray-300">
                            <path fill="currentColor"
                                  d="M4 15h2v5h12V4H6v5H4V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-6zm6-4V8l5 4-5 4v-3H2v-2h8z"></path>
                        </svg>
                        <h2 className="mt-1 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-gray-300">
                            Connectez-vous
                        </h2>
                    </div>

                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                        <form className="space-y-6" onSubmit={handleSubmit}>
                            <div>
                                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-300">
                                    Utilisateur :
                                </label>
                                <div className="mt-2">
                                    <input
                                        tabIndex={1}
                                        id="username"
                                        name="username"
                                        type="username"
                                        autoComplete="username"
                                        onChange={e => setUsername(e.target.value)}
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div>
                                <div className="flex items-center justify-between">
                                    <label htmlFor="password"
                                           className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-300">
                                        Mot de passe :
                                    </label>
                                    <div className="text-sm">
                                        <NavLink to={"/help"}>
                                            <a tabIndex={2}
                                               className="font-semibold text-indigo-600 dark:text-indigo-400 hover:text-indigo-500">
                                                Mot de passe oublié ?
                                            </a>
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <input
                                        tabIndex={1}
                                        id="password"
                                        name="password"
                                        type="password"
                                        autoComplete="current-password"
                                        onChange={e => setPassword(e.target.value)}
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div>
                                <button
                                    tabIndex={1}
                                    type="submit"
                                    className="flex w-full justify-center rounded-md bg-indigo-600 dark:bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Connexion
                                </button>
                            </div>
                        </form>

                        <p className="mt-10 text-center text-sm text-gray-500 dark:text-gray-400">
                            Pas de compte ?{' '}
                            <NavLink to={"/help"}>
                                <a tabIndex={2}
                                   className="font-semibold leading-6 text-indigo-600 dark:text-indigo-400 hover:text-indigo-500 ">
                                    Contactez le support
                                </a>
                            </NavLink>
                        </p>
                    </div>
                </div>
            )}
            {isAuthenticated && (
                <div className="border-b border-gray-200 px-6 py-5 sm:px-6">
                    <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                        <div className="ml-10 mt-2">
                            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-300">Vous êtes connecté</h3>
                        </div>
                        <div className="ml-10 mt-2 flex-shrink-0">
                            <button
                                type="button"
                                onClick={handleLogout}
                                className="relative inline-flex items-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-400"
                            >
                                Se déconnecter
                            </button>
                        </div>
                    </div>
                </div>

            )}
        </>
    );
};

export default Login;